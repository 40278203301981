import React, { useState, useEffect } from "react";
import Footer from "../../../Components/Footer/Footer";
import CustomerSideBar from "../../../Components/sidebar/CustomerSideBar";
import CustHeader from "../../../Components/header/Header/CustHeader";
import AddInvoiceToPay from "./AddInvoiceToPay";

import Skeleton from "@mui/material/Skeleton";
import {
  Box,
  Stack,
  Paper,
  Divider,
  Typography,
  Button,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import { SessionVerification } from "../../../Components/SessionManagement/SessionManagement";
import { Autocomplete } from "@mui/joy";
import configServ from "../../../services/config";
import MoneyIcon from "@mui/icons-material/Money";
import AlertDialog from "../../../Components/AlertDialog/AlertDialog";

const Pay = () => {
  //#region code
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const open = useSelector((state) => state.sideExpand.open);
  const [invoicelist, setInvoiceList] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [totalDue, setTotalDue] = useState(0);
  const [paySource, setPaySource] = useState(["cash", "bank"]);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    pay_against: "invoice_pay",
  });
  const [customer, setCustomer] = useState({});
  const [invoiceToPay, setInvoiceToPay] = useState([
    {
      id: 1,
      invoice_id: 0,
      invoice_no: "",
      due: 0,
      amountPaying: 0,
    },
  ]);
  const [totalAmountToPay, setTotalAmountToPay] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent("");
  };
  const handleAddItem = () => {
    setInvoiceToPay((state) => [
      ...state,
      {
        id: invoiceToPay[invoiceToPay.length - 1].id + 1,
        invoice_no: "",
        due: 0,
        amountPaying: 0,
      },
    ]);
  };

  const delItem = (id) => {
    const updatedItemList = invoiceToPay.filter((item) => item.id !== id);
    setInvoiceToPay(updatedItemList);
    // console.log(updatedItemList)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const fetchCustomerDetails = async () => {
    try {
      setLoader(true);
      const result = await configServ.getCustomerById({ customer_id: user_id });
      setCustomer(result.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const fetchInvoiceList = async () => {
    try {
      const result = await configServ.getDueAmouont({ user_id: user_id });
      // console.log(result)
      // setInvoice(result)
      fetchInvoiceNumbmer(result);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchInvoiceNumbmer = (data) => {
    let total = 0;
    data.map((item) => {
      // console.log(item.invoice_number)
      if (item.invoice_number === null) {
        return;
      }
      setInvoiceList((state) => [
        ...state,
        {
          label: item.invoice,
          id: item.id,
          balance: item.balance,
        },
      ]);
      total = total + item.balance;
    });
    setTotalDue(total.toFixed(2));
    // console.log(invoice)
  };

  const amountToPayCalculation = (e) => {
    let total = 0;
    if (formData.pay_against === "invoice_pay") {
      invoiceToPay.map((item) => {
        if (isNaN(item.amountPaying)) {
          return;
        }
        total = total + parseFloat(item.amountPaying.toFixed(2));
      });
      setTotalAmountToPay(total);
    } else {
      setTotalAmountToPay(parseFloat(e.target.value));
    }
  };

  useEffect(() => {
    amountToPayCalculation();
  }, [invoiceToPay]);

  useEffect(() => {
    fetchInvoiceList();
    fetchCustomerDetails();
  }, []);

  const fetchInvoiceById = async (id) => {
    try {
      const result = await configServ.getInvoiceById({ inv_id: id });
      console.log(result);
      const selected = invoicelist.filter(
        (item) => result.invoice.id === item.id
      );
      console.log(selected[0].balance);
      setInvoiceDetails({
        ...result,
        balance: selected[0].balance,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const invoiceOnchangeHandler = (e, value) => {
    console.log(value);
    if (value !== null) {
      fetchInvoiceById(value.id);
    } else {
      setInvoiceDetails([]);
    }
  };

  const handleSubmit = async () => {
    if (!totalAmountToPay) {
      handleOpenDialog("Please enter valid amount to pay");
      return;
    } else {
      const dataToSend = {
        customer: customer,
        amountToPay: totalAmountToPay,
        invoice: invoiceToPay,
        ...formData,
        company_id: company_id,
      };
      console.log(dataToSend);
      try {
        const result = await configServ.makePayment(dataToSend);
        console.log(result);
        handleOpenDialog("Payment successfull");
        clear();
      } catch (err) {
        console.log(err);
        handleOpenDialog("Payment failed");
      }
    }
  };

  const handleInvoiceToPay = (data, id) => {
    const updatedInvoiceToPay = invoiceToPay.map((item) => {
      return item.id === id
        ? {
            ...item,
            invoice_id: data.invoice.id,
            invoice_no: data.invoice.invoice_number,
            due: data.balance,
          }
        : item;
    });
    setInvoiceToPay(updatedInvoiceToPay);
  };

  const handleAmountToPay = (data, id) => {
    const updatedAmountToPay = invoiceToPay.map((item) => {
      return item.id === id
        ? {
            ...item,
            amountPaying: parseFloat(data),
          }
        : item;
    });
    setInvoiceToPay(updatedAmountToPay);
  };

  const clear = () => {
    setFormData({
      pay_against: "invoice_pay",
    });
    setInvoiceToPay([
      {
        id: 1,
        invoice_id: 0,
        invoice_no: "",
        due: 0,
        amountPaying: 0,
      },
    ]);
    setTotalAmountToPay(0);
  };

  const payFullAmount = () => {
    setFormData((state) => ({
      ...state,
      amountToPay: invoiceDetails.balance,
    }));
  };
  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />
      <Grid container spacing={2}>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#333333aa",
          }}
        >
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <b>Customer Name :</b>&nbsp;
            {loader ? (
              <Skeleton
                variant="text"
                width={230}
                sx={{ display: "inline-block" }}
              />
            ) : (
              <span>{customer.contact_name}</span>
            )}
          </Typography>
        </Grid>
        {/* <Grid item md={4} sm={6} xs={12}>
                                <Autocomplete
                                    placeholder="Select Invoice(s)"
                                    options={invoicelist || ['Loading...']}
                                    sx={{
                                        width: '100%',
                                    }}
                                    onChange={invoiceOnchangeHandler}
                                />
                            </Grid> */}
        {/* <Grid item md={4} sm={6} xs={12}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#33333399'
                                }}
                            >
                                <Typography>
                                    <b>Total Due :</b> {totalDue}
                                </Typography>
                            </Grid> */}
        {/* <br/><br/><br/> */}
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#333333aa",
          }}
        >
          <FormControl>
            <RadioGroup
              row
              name="pay_against"
              onChange={handleChange}
              value={formData.pay_against}
            >
              <FormControlLabel
                value="invoice_pay"
                control={<Radio size="small" />}
                label="Pay against invoice(s)"
              />
              <FormControlLabel
                value="no_invoice_pay"
                control={<Radio size="small" />}
                label="Pay without invoice"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Divider sx={{ backgroundColor: "#333", width: "100%", my: 3 }} />

        {formData.pay_against === "invoice_pay" && (
          <Grid item xs={12}>
            <Stack
              spacing={1}
              sx={{
                border: "1px solid #33333344",
                width: "100%",
                padding: 2,
                borderRadius: 2,
                mb: 2,
              }}
            >
              {invoiceToPay.map((item) => (
                <AddInvoiceToPay
                  key={item.id}
                  data={item}
                  invoicelist={invoicelist}
                  handleAddItem={handleAddItem}
                  delItem={delItem}
                  isDel={invoiceToPay.length > 1}
                  handleInvoiceToPay={handleInvoiceToPay}
                  handleAmountToPay={handleAmountToPay}
                />
              ))}
            </Stack>
          </Grid>
        )}

        {formData.pay_against === "no_invoice_pay" && (
          <Grid item xs={12}>
            <TextField
              label="Amount To Pay"
              type="number"
              size="small"
              fullWidth
              onChange={amountToPayCalculation}
            />
          </Grid>
        )}

        {/* <Grid item md={4} sm={6} xs={12}>
                                <TextField
                                    label="Customer Name"
                                    size="small"
                                    fullWidth
                                    aria-readonly
                                    value={invoiceDetails.invoice?.partyname || ''}
                                />
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <TextField
                                    label="Amount"
                                    size="small"
                                    fullWidth
                                    aria-readonly
                                    value={invoiceDetails.invoice?.total_amount || ''}
                                />
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <TextField
                                    label="Dues Amount"
                                    size="small"
                                    fullWidth
                                    aria-readonly
                                    value={invoiceDetails.balance || ''}
                                />
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <TextField
                                    label="Amout To Pay"
                                    size="small"
                                    name='amountToPay'
                                    fullWidth
                                    onChange={handleChange}
                                    value={formData.amountToPay || ''}
                                    helperText={'Enter the amount you want to pay now.'}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <Tooltip title="Pay full amount" arrow>
                                                <IconButton onClick={payFullAmount}>
                                                    <MoneyIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>,
                                    }}
                                />
                            </Grid> */}
        <Grid
          item
          md={4}
          sm={6}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Typography
            variant="body"
            sx={{
              color: "#333333aa",
            }}
          >
            <b>Total amount to pay :</b> {totalAmountToPay}
          </Typography>
        </Grid>
        <Grid
          item
          md={4}
          sm={6}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          <FormControl fullWidth size="small">
            <InputLabel>Payment mode</InputLabel>
            <Select
              required
              name="pay_source"
              label="Payment mode"
              value={formData.pay_source || ""}
              onChange={handleChange}
            >
              {paySource.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          md={4}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Button fullWidth variant="contained" onClick={handleSubmit}>
            Proceed
          </Button>
        </Grid>
      </Grid>
      {/* <Footer /> */}
    </>
  );
};
export default Pay;
