import http from "./http";
import axios from "axios";

//---------------------------------------------Customer API----------------------------------------------------------------
const login = (data) => {
    return http.post('/api/login', data)
}
const dashboardsummary = (data) => {
    return http.post('/api/dashboardsummary', data)
}
const resetAdminPassword = (data) => {
    return http.post('/api/admin-reset-password', data)
}
const resetCustomerPassword = (data) => {
    return http.post('/api/reset-password', data)
}
const profiledetail = (data) => {
    return http.post('/api/profiledetail', data)
}
const invoicelist = (data) => {
    return http.post('/api/invoicelist', data)
}
const GetCustomerInvoiceList = (data) => {
    return http.post('/api/get-customer-invoice-list', data)
}
const invoice_detail = (data) => {
    return http.post('/api/invoice_detail', data)
}
const getInvoiceById = (data) => {
    return http.post('/api/get-invoice', data)
}
const customer_order_list = (data) => {
    return http.post('/api/customer_order_list', data)
}
const order_details = (data) => {
    return http.post('/api/order_details', data)
}
const payment_list = (data) => {
    return http.post('/api/payment_list', data)
}
const getsubs = (data) => {
    return http.post('/api/getsubs', data)
}
const getitem = (data) => {
    return http.post('/api/admin/getitem', data)
}
const getcategory = (data) => {
    return http.post('/api/get-category', data)
}
const getcategoryBySuperCategory = (data) => {
    return http.post('/api/admin/getcategory-by-super-category', data)
}
const getcategoryByBrand = (data) => {
    return http.post('/api/admin/getcategory-by-brand', data)
}
const getBrand = (data) => {
    return http.post('/api/get-brands', data)
}
const getGroup = (data) => {
    return http.post('/api/get-group', data)
}
const groupByCategory = (data) => {
    return http.post('/api/admin/group-by-category', data)
}
const getUnit = (data) => {
    return http.post('/api/get-unit', data)
}
const get_item_img = (data) => {
    return http.post('/api/admin/get_item_img', data)
}
const getitemdetail = (data) => {
    return http.post('/api/admin/getitemdetail', data)
}
const getrecenttrans = (data) => {
    return http.post('/api/getrecenttrans', data)
}
const order = (data) => {
    return http.post('/api/order_entry', data)
}
const orderItem = (data) => {
    return http.post('/api/order_item_entry', data)
}
const placeOrder = (data) => {
    return http.post('/api/place-order', data)
}
const company_details = (data) => {
    return http.post('/api/company', data)
}
const company_logo = (data) => {
    return http.post('/api/company-logo', data)
}
const getItemDetailsByCompId = (data) => {
    return http.post('/api/getitemdetailsbycomp', data)
}
const getItemDetailsByCompIdPage = (data) => {
    return http.post('/api/getitemdetailsbycomppage', data)
}
const getCatalogCustomer = (data) => {
    return http.post('/api/get-catalog-customer', data)
}
const getDueAmouont = (data) => {
    return http.post('/api/due-amount', data)
}
const makePayment = (data) => {
    return http.post('/api/make-payment', data)
}
const getCompanyById = (data) => {
    return http.post('/api/get-company-details', data)
}
const getFirmList = (data) => {
    return http.post('/api/get-firm-list',data)
}
const getTowerList = (data) => {
    return http.post('/api/get-Tower', data)
}
const getEventList = () => {
    return http.post('/api/get-event-list')
}
const registation = (data) => {
    return http.post('/api/RegistationN', data)
}
//-----------------------------------------------Admin API--------------------------------------------------------------------------------
const admin_login = (data) => {
    return http.post('/api/admin-login', data)
}
const user_admin_login = (data) => {
    return http.post('/api/user-admin-login', data)
}
const recentInvoiceList = (company_id) => {
    return http.get(`/api/admin/invoice-list-recent?company_id=${company_id}`)
}
const invoiceListAdmin = (data) => {
    return http.post(`/api/invoice-list`, data)
}
const itemDetails = (data) => {
    return http.get(`/api/admin/item_details?id=${data}`)
}
const salesOrderList = (data) => {
    return http.post('/api/admin/order_list', data)
}
const getCustomerById = (data) => {
    return http.post('/api/get-customer-detail', data)
}
const getCompany = (data) => {
    return http.post('/api/company', data)
}
const getHsnList = (data) => {
    return http.post('/api/get-hsn', data)
}
const createItem = (data) => {
    return http.post('/api/create-item', data)
}
const updateItem = (data) => {
    return http.post('/api/update-item', data)
}
const getItemList = (data) => {
    return http.post('/api/get-item-list', data)
}
const getStockList = (data) => {
    return http.post('/api/get-stock-list', data);
}
const AddItemImage = (data) => {
    return http.post('/api/admin/add_item_img', data)
}
const AddBase64ItemImage = (data) => {
    return http.post('/api/add_base64_item_img', data)
}
const addItemSize = (data) => {
    return http.post('/api/add_item_size', data)
}
const getItemSize = (data) => {
    return http.post('/api/get_item_size', data)
}
const updateItemSize = (data) => {
    return http.post('/api/update_item_size', data)
}
const updateItemImage = (data) => {
    return http.post('/api/update_item_image', data)
}
const get_customer = (data) => {
    return http.post('/api/get-customer', data)
}
const getShipToAddress = (data) => {
    return http.post('/api/get-shiptoaddress', data)
}
const addGroup = (data) => {
    return http.post('/api/add-group', data)
}
const editGroup = (data) => {
    return http.post('/api/edit-group', data)
}
const addCategory = (data) => {
    return http.post('/api/add-category', data)
}
const editCategory = (data) => {
    return http.post('/api/edit-category', data)
}
const addBrand = (data) => {
    return http.post('/api/add-brand', data)
}
const editBrand = (data) => {
    return http.post('/api/edit-brand', data)
}
const addUnit = (data) => {
    return http.post('/api/add-unit', data)
}
const editUnit = (data) => {
    return http.post('/api/edit-unit', data)
}
const getSimpleUnit = (data) => {
    return http.post('/api/get-simple-unit', data)
}
const addHsn = (data) => {
    return http.post('/api/add-hsn', data)
}
const editHsn = (data) => {
    return http.post('/api/edit-hsn', data)
}
const createInvoice = (data) => {
    return http.post('/api/create-invoice', data)
}
const editInvoice = (data) => {
    return http.post('/api/edit-invoice', data)
}
const getAssociateItem = (data) => {
    return http.post('/api/get-associated-item', data)
}
const getCustomerList = (data) => {
    return http.post('/api/get-customer-list', data)
}
const createSaleOrder = (data) => {
    return http.post('/api/create_sales_order', data)
}
const editSaleOrder = (data) => {
    return http.post('/api/edit_sales_order', data)
}
const addCustomer = (data) => {
    return http.post('/api/add-customer', data)
}
const editCustomer = (data) => {
    return http.post('/api/edit-customer', data)
}

const getSupplier = (data) => {
    return http.post('/api/get-supplier', data)
}
const getSuppliers = (data) => {
    return http.post('/api/get-suppliers', data)
}
const addSupplier = (data) => {
    return http.post('/api/add-supplier', data)
}
const editSupplier = (data) => {
    return http.post('/api/edit-supplier', data)
}
const createPurchaseOrder = (data) => {
    return http.post('/api/create-purchase-order', data)
}
const editPurchaseOrder = (data) => {
    return http.post('/api/update-purchase-order', data)
}
const getAdminDetails = (data) => {
    return http.post('/api/get-admin', data)
}
const purchaseOrderList = (data) => {
    return http.post('/api/get-purchase-orders', data);
}
const purchaseOrderNumbers = (data) => {
    return http.post('/api/get-purchase-order-numbers', data);
}
const purchaseOrderByOrderNo = (data) => {
    return http.post('/api/get-purchase-master-by-order-number', data);
}
const getCompanyList = (data) => {
    return http.post('/api/get-company-list', data)
}
const setActiveCompany = (data) => {
    return http.post('/api/set-active-company', data)
}
const addLocation = (data) => {
    return http.post('/api/add-location', data)
}
const editLocation = (data) => {
    return http.post('/api/edit-location', data)
}
const getLocation = (data) => {
    return http.post('/api/get-location', data)
}
const getLocationById = (data) => {
    return http.post('/api/get-location-by-id', data)
}
const getSubscriptionList = (data) => {
    return http.post('/api/get-subscription-list', data)
}
const getOrderDetails = (data) => {
    return http.post('/api/get-order-details', data)
}
const printSalesOrder = (data) => {
    return http.post('/api/print-order', data)
}
const addCompany = (data) => {
    return http.post('/api/create-company', data)
}
const editCompany = (data) => {
    return http.post('/api/edit-company', data)
}
const getAdminList = () => {
    return http.get('/api/get-admin-list')
}
const getStateCodeByname = (data) => {
    return http.post('/api/state-code-by-name', data)
}
const getStateByCidAddress = (data) => {
    return http.post('/api/state-by-cid-address', data)
}
const getStateBySupplier = (data) => {
    return http.post('/api/state-by-supplier', data)
}
const getCustomerContact = (data) => {
    return http.post('/api/get-contact', data)
}
const addCustomerContact = (data) => {
    return http.post('/api/add-contact', data)
}
const editCustomerContact = (data) => {
    return http.post('/api/edit-contact', data)
}
const getLocationByCompanyType = (data) => {
    return http.post('/api/location-compid', data)
}
const getLocationAdminComp = (data) => {
    return http.post('/api/location-compid-adminid', data)
}
const getDashboardCount = (data) => {
    return http.post('/api/dashboard-count', data)
}
const createCatalog = (data) => {
    return http.post('/api/create-catalog', data)
}
const getCatalog = (data) => {
    return http.post('/api/get-catalog', data)
}
const editCatalog = (data) => {
    return http.post('/api/edit-catalog', data)
}
const printInvoice = (data) => {
    return http.post('/api/print_invoice2', data)
}
const printVoucher = (data) => {
    return http.post('/api/print-voucher', data)
}
const getInvoiceByCompany = (data) => {
    return http.post('/api/get-invoice-by-company', data)
}
const getDispatch = (data) => {
    return http.post('/api/get-dispatch', data)
}
const createDispatch = (data) => {
    return http.post('/api/create-dispatch', data)
}
const editDispatch = (data) => {
    return http.post('/api/edit-dispatch', data)
}
const updateDispatch = (data) => {
    return http.post('/api/update-dispatch', data)
}
const getpaymentListAdmin = (data) => {
    return http.post('/api/get-paymentlist-admin', data)
}
const getCompanyDetails = (data) => {
    return http.post('/api/get-company', data)
}
const getNeighbourhoodType = (data) => {
    return http.post('/api/get-neighbourhood-type', data)
}
const addNeighbourhood = (data) => {
    return http.post('/api/add-neighbourhood', data)
}
const getNeighbourhood = (data) => {
    return http.post('/api/get-neighbourhood', data)
}
const editNeighbourhood = (data) => {
    return http.post('/api/edit-neighbourhood', data)
}
const addLocationImg = (data) => {
    return http.post('/api/add-location-img', data)
}
const editLocationImg = (data) => {
    return http.post('/api/edit-location-img', data)
}
const getLocationImg = (data) => {
    return http.post('/api/get-location-img', data)
}
const createSuperCategory = (data) => {
    return http.post('/api/create-super-category', data)
}
const editSuperCategory = (data) => {
    return http.post('/api/edit-super-category', data)
}
const getSuperCategory = (data) => {
    return http.post('/api/get-super-category', data)
}
const deleteSuperCategory = (data) => {
    return http.post('/api/del-super-category', data)
}
const getApplication = () => {
    return http.get('/api/get-application')
}
const getCountry = () => {
    return http.post('/api/get-country')
}
const getCountryId = (data) => {
    return http.post('/api/get-country-id', data);
}
const getState = (data) => {
    return http.post('/api/get-state', data)
}
const getCity = (data) => {
    return http.post('/api/get-city', data)
}
const getAllCities = (data) => {
    return http.get('/api/get-all-city', data)
}
const addCity = (data) => {
    return http.post('/api/add-city', data)
}
const updateCity = (data) => {
    return http.post('/api/update-city', data)
}
const addMailConfiguration = (data) => {
    return http.post('/api/add-mail-configuration', data)
}
const getMailConfigurations = (data) => {
    return http.post('/api/get-mail-configuration', data)
}
const editMailConfigurations = (data) => {
    return http.post('/api/update-mail-configuration', data)
}
const getRoles = (data) => {
    return http.post('/api/get-roles', data)
}
const createRole = (data) => {
    return http.post('/api/create-role', data)
}
const updateRole = (data) => {
    return http.post('/api/update-role', data)
}
const getPermissions = (data) => {
    return http.post('/api/get-permissions', data)
}
const createAccessMasterUser = (data) => {
    return http.post('/api/create-access-master-user', data)
}
const updateAccessMasterUser = (data) => {
    return http.post('/api/update-access-master-user', data)
}
const getAccessMasterUser = (data) => {
    return http.post('/api/get-access-master-user', data)
}
const createLineItems = (data) => {
    return http.post('/api/create-line-item', data)
}
const DeactivateLineItems = (data) => {
    return http.post('/api/deactivate-line-item', data)
}
const getLineItems = (data) => {
    return http.post('/api/get-line-items', data)
}
const getLineItemQR = (data) => {
    return http.post('/api/get-line-item-qr', data)
}
const getBatch = (data) => {
    return http.post('/api/get-batch', data)
}
const createBatch = (data) => {
    return http.post('/api/create-batch', data)
}
const updateBatch = (data) => {
    return http.post('/api/update-batch', data)
}
const deactivateBatch = (data) => {
    return http.post('/api/deactivate-batch', data)
}
const createPurchaseBill = (data) => {
    return http.post('/api/create-purchase-bill', data)
}
const updatePurchaseBill = (data) => {
    return http.post('/api/update-purchase-bill', data)
}
const getPurchaseBills = (data) => {
    return http.post('/api/get-purchase-bills', data)
}
const getPurchaseBillItems = (data) => {
    return http.post('/api/get-purchase-bill-items', data)
}
const getPurchaseOrderItems = (data) => {
    return http.post('/api/get-purchase-order-items', data)
}
const getInvoiceByCustomerID = (data) => {
    return http.post('/api/get-invoice-by-customer', data)
}
const createAccountMaster = (data) => {
    return http.post('/api/create-account-master', data)
}
const updateAccountMaster = (data) => {
    return http.post('/api/update-account-master', data)
}
const getAccountMasters = (data) => {
    return http.post('/api/get-account-masters', data)
}
const getAccountMasterList = (data) => {
    return http.post('/api/get-account-masters-list', data)
}
const geVoucherEntrytAccount = (data) => {
    return http.post('/api/get-voucher-entry-accounts', data)
}
const getAllAccountMasters = (data) => {
    return http.post('/api/get-all-account-masters', data)
}
const getAccountClassified = (data) => {
    return http.post('/api/get-account-classified', data)
}
const createVouchers = (data) => {
    return http.post('/api/create-vouchers', data)
}
const updateVouchers = (data) => {
    return http.post('/api/update-vouchers', data)
}
const createJournalVouchers = (data) => {
    return http.post('/api/create-journal-vouchers', data)
}
const updateJournalVouchers = (data) => {
    return http.post('/api/update-journal-vouchers', data)
}
const getVouchers = (data) => {
    return http.post('/api/get-vouchers', data)
}
const getJournalVouchers = (data) => {
    return http.post('/api/get-journal-vouchers', data)
}
const getCustomerLedger = (data) => {
    return http.post('/api/get-customer-ledger', data)
}
const getVoucher = (data) => {
    return http.post('/api/get-voucher', data)
}
const getVoucherList = (data) => {
    return http.post('/api/get-voucher-list', data)
}
const getPaymentList = (data) => {
    return http.post('/api/get-payment-list', data);
}
const getPaymentReport = (data) => {
    return http.post('/api/get-payment-report', data);
}
const addUnregisterPayment = (data) => {
    return http.post('/api/create-unregister-payment', data);
}
const updateUnregisterPayment = (data) => {
    return http.post('/api/update-unregister-payment', data);
}
const getUnregisterPayments = (data) => {
    return http.post('/api/get-unregister-payments', data);
}
const deactivateUnregisterPayment = (data) => {
    return http.post('/api/deactivate-unregister-payment', data);
}
const getItemLedger = (data) => {
    return http.post('/api/get-item-ledger', data);
}
const createSession = (data) => {
    return http.post('/api/create-session', data);
}
const getSessions = (data) => {
    return http.post('/api/get-sessions', data);
}
const getSupplierLedger = (data) => {
    return http.post('/api/get-supplier-ledger', data);
}
const createFollowUp = (data) => {
    return http.post('/api/create-followup', data);
}
const updateFollowUp = (data) => {
    return http.post('/api/update-followup', data);
}
const getFollowUp = (data) => {
    return http.post('/api/get-followup', data);
}
const getAccountTransactions = (data) => {
    return http.post('/api/get-account-transcations', data);
}
const getAccountName = (data) => {
    return http.post('/api/get-account-name', data);
}
const checkItemCode = (data) => {
    return http.post('/api/check-item-code', data);
}
const checkItemName = (data) => {
    return http.post('/api/check-item-name', data);
}
const checkPurchaseOrderNo = (data) => {
    return http.post('/api/check-purchase-order-no', data);
}
const checkPurchaseBillNo = (data) => {
    return http.post('/api/check-purchase-bill-no', data);
}
const checkCompanyPrefix = (data) => {
    return http.post('/api/check-company-prefix', data);
}
const getGstInvoiceReport = (data) => {
    return http.post('/api/get-invoice-report', data);
}
const stopFollowUpNotification = (data) => {
    return http.post('/api/stop-followup-notification', data);
}
const checkInvoiceNumber = (data) => {
    return http.post('/api/check-invoice-number', data);
}
const checkCustomerName = (data) => {
    return http.post('/api/check-customer-name', data);
}
const checkSupplierName = (data) => {
    return http.post('/api/check-supplier-name', data);
}
const getAllTaxTypes = (data) => {
    return http.get('/api/get-tax-types', data);
}
const getCustomerEmailByID = (data) => {
    return http.post('/api/get-customer-email', data);
}
const getPrimaryCompany = () => {
    return http.get('/api/get-primary-company');
}
const createInvoiceGst = (data) => {
    return http.post('/api/create-invoice-gst', data);
}
const updateStockQuantity = (data) => {
    return http.post('/api/update-stock-quantity', data);
}
const createPermissions = (data) => {
    return http.post('/api/create-permission', data);
}
const getUserAdmin = (data) => {
    return http.post('/api/get-user-admin', data);
}
const sendSupportMail = (data) => {
    return http.post('/api/send-support-mail', data);
}
const getAgentType = () => {
    return http.get('/api/get-agent-type');
}
const getSubscriptionType = () => {
    return http.get('/api/get-subscription-type');
}
const approveSaleOrders = (data) => {
    return http.post('/api/approve-sale-orders', data)
}
const checkOrderNumber = (data) => {
    return http.post('/api/check-order-number', data);
}
const getSalesOrderReport = (data) => {
    return http.post('/api/sales-order-report', data);
}
const getOrdersSummary = (data) => {
    return http.post('/api/sales-order-summary', data);
}
const createOrUpdateConfiguration = (data) => {
    return http.post('/api/create-update-configuration', data);
}
const getConfiguration = (data) => {
    return http.post('/api/get-configuration', data);
}
const getOrdersSummaryDetails = (data) => {
    return http.post('/api/sales-order-summary-detail', data);
}
const getOrdersSummaryItems = (data) => {
    return http.post('/api/sales-order-summary-items', data);
}
const getSalesSummary = (data) => {
    return http.post('/api/get-sales-summary', data);
}
const getSalesSummaryDetail = (data) => {
    return http.post('/api/get-sales-summary-detail', data);
}
const getSalesSummaryItems = (data) => {
    return http.post('/api/get-sales-summary-items', data);
}
const getAllCompanyList = (data) => {
    return http.post('/api/get-all-company-list', data);
}
const updateAdminDetails = (data) => {
    return http.post('/api/update-admin-details', data);
}
const deleteInvoice = (data) => {
    return http.post('/api/delete-invoice', data);
}
const getAllLedgers = (data) => {
    return http.post('/api/get-all-ledgers', data);
}

//-----------------------------------------------Outside dcrm-----------------------------------------------------------
const createEInvoice = async (data) => {
    const baseUrl = 'http://localhost:37597'
    // const baseUrl = 'http://egstapi.d-crm.in'
    const url = '/api/Invoice/GenerateIRN'
    const response = await axios.post(`${baseUrl}${url}`, data);
    return response
    // response.then((res) => {
    //     // console.log('Response:', res);
    //     return res
    // }).catch((err) => {
    //     // console.error('Error:', err);
    //     return err
    // });
}




const configServ = {
    //-----------------------------------------------Customer-----------------------------------------------------------
    login,
    dashboardsummary,
    resetAdminPassword,
    resetCustomerPassword,
    profiledetail,
    invoicelist,
    invoice_detail,
    getInvoiceById,
    customer_order_list,
    order_details,
    payment_list,
    getsubs,
    getitem,
    getcategory,
    getcategoryBySuperCategory,
    getcategoryByBrand,
    getBrand,
    getGroup,
    groupByCategory,
    getUnit,
    get_item_img,
    getitemdetail,
    getrecenttrans,
    order,
    orderItem,
    placeOrder,
    company_details,
    company_logo,
    getItemDetailsByCompId,
    getItemDetailsByCompIdPage,
    getCatalogCustomer,
    getDueAmouont,
    makePayment,
    getSuperCategory,
    deleteSuperCategory,
    getCompanyById,
    getFirmList,
    getTowerList,
    getEventList,
    registation,
    //-------------------------------------------------Admin---------------------------------------------------------------     
    admin_login,
    user_admin_login,
    recentInvoiceList,
    invoiceListAdmin,
    itemDetails,
    salesOrderList,
    getCustomerById,
    getCompany,
    getHsnList,
    createItem,
    updateItem,
    getItemList,
    getStockList,
    AddItemImage,
    AddBase64ItemImage,
    addItemSize,
    getItemSize,
    updateItemSize,
    updateItemImage,
    get_customer,
    getShipToAddress,
    addGroup,
    editGroup,
    addCategory,
    editCategory,
    addBrand,
    editBrand,
    addUnit,
    editUnit,
    getSimpleUnit,
    addHsn,
    editHsn,
    createInvoice,
    editInvoice,
    getAssociateItem,
    getCustomerList,
    createSaleOrder,
    editSaleOrder,
    addCustomer,
    editCustomer,
    getSupplier,
    getSuppliers,
    addSupplier,
    editSupplier,
    createPurchaseOrder,
    editPurchaseOrder,
    getAdminDetails,
    purchaseOrderList,
    purchaseOrderNumbers,
    purchaseOrderByOrderNo,
    getCompanyList,
    setActiveCompany,
    addLocation,
    editLocation,
    getLocation,
    getLocationById,
    getSubscriptionList,
    getOrderDetails,
    printSalesOrder,
    addCompany,
    editCompany,
    getAdminList,
    getStateCodeByname,
    getStateByCidAddress,
    getStateBySupplier,
    getCustomerContact,
    addCustomerContact,
    editCustomerContact,
    getLocationByCompanyType,
    getLocationAdminComp,
    getDashboardCount,
    createCatalog,
    getCatalog,
    editCatalog,
    printInvoice,
    printVoucher,
    getInvoiceByCompany,
    getDispatch,
    createDispatch,
    editDispatch,
    updateDispatch,
    getpaymentListAdmin,
    getCompanyDetails,
    getNeighbourhoodType,
    addNeighbourhood,
    getNeighbourhood,
    editNeighbourhood,
    addLocationImg,
    editLocationImg,
    getLocationImg,
    createSuperCategory,
    editSuperCategory,
    getApplication,
    getCountry,
    getCountryId,
    getState,
    getCity,
    getAllCities,
    addCity,
    updateCity,
    addMailConfiguration,
    getMailConfigurations,
    editMailConfigurations,
    getRoles,
    createRole,
    updateRole,
    getPermissions,
    createAccessMasterUser,
    updateAccessMasterUser,
    getAccessMasterUser,
    createLineItems,
    DeactivateLineItems,
    getLineItems,
    getLineItemQR,
    getBatch,
    createBatch,
    updateBatch,
    deactivateBatch,
    createPurchaseBill,
    updatePurchaseBill,
    getPurchaseBills,
    getPurchaseBillItems,
    getPurchaseOrderItems,
    getInvoiceByCustomerID,
    createAccountMaster,
    updateAccountMaster,
    getAccountMasters,
    getAccountMasterList,
    geVoucherEntrytAccount,
    getAllAccountMasters,
    getAccountClassified,
    createVouchers,
    updateVouchers,
    createJournalVouchers,
    updateJournalVouchers,
    getVouchers,
    getJournalVouchers,
    getCustomerLedger,
    getVoucher,
    getVoucherList,
    getPaymentList,
    getPaymentReport,
    addUnregisterPayment,
    updateUnregisterPayment,
    getUnregisterPayments,
    deactivateUnregisterPayment,
    getItemLedger,
    createSession,
    getSessions,
    getSupplierLedger,
    createFollowUp,
    updateFollowUp,
    getFollowUp,
    getAccountTransactions,
    getAccountName,
    checkItemCode,
    checkItemName,
    checkPurchaseOrderNo,
    checkPurchaseBillNo,
    checkCompanyPrefix,
    getGstInvoiceReport,
    stopFollowUpNotification,
    checkInvoiceNumber,
    checkCustomerName,
    checkSupplierName,
    getAllTaxTypes,
    getCustomerEmailByID,
    getPrimaryCompany,
    createInvoiceGst,
    updateStockQuantity,
    createPermissions,
    getUserAdmin,
    sendSupportMail,
    getAgentType,
    getSubscriptionType,
    approveSaleOrders,
    checkOrderNumber,
    getSalesOrderReport,
    getOrdersSummary,
    createOrUpdateConfiguration,
    getConfiguration,
    getOrdersSummaryDetails,
    getOrdersSummaryItems,
    getSalesSummary,
    getSalesSummaryDetail,
    getSalesSummaryItems,
    getAllCompanyList,
    updateAdminDetails,
    deleteInvoice,
    getAllLedgers,
    GetCustomerInvoiceList,
    //-----------------------------------------------Outside dcrm-----------------------------------------------------------
    createEInvoice,
}

export default configServ;